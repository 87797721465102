.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
  perspective: 2000px;
  transform: rotateZ(0) rotateX(-30deg) rotateY(-45deg);
  animation: AnimateContainer 3s infinite;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
  transform: translate3d(0em, 3em, 1.5em);
}
.wrapper:last-child {
  transform: rotateY(-90deg) rotateX(90deg) translate3d(0, 3em, 1.5em);
}
.wrapper:first-child {
  transform: rotateZ(-90deg) rotateX(-90deg) translate3d(0, 3em, 1.5em);
}
.wrapper:nth-child(1) .cube {
  background-color: #9b83e8;
}
.wrapper:nth-child(1) .cube:before {
  background-color: #5638c0;
}
.wrapper:nth-child(1) .cube:after {
  background-color: #303354;
}
.wrapper:nth-child(2) .cube {
  background-color: #d6cee0;
}
.wrapper:nth-child(2) .cube:before {
  background-color: #5f40b7;
}
.wrapper:nth-child(2) .cube:after {
  background-color: #7d6bc4;
}
.wrapper:nth-child(3) .cube {
  background-color: #f9f018;
}
.wrapper:nth-child(3) .cube:before {
  background-color: #c7b307;
}
.wrapper:nth-child(3) .cube:after {
  background-color: #fcfea0;
}

.cube {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
  width: 3em;
  height: 3em;
  transform: translate3d(-1.5em, -3em, 0) scaleY(0.01);
  animation: AnimateCube 3s infinite;
}
.cube:before,
.cube:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}
.cube:before {
  left: 100%;
  bottom: 0;
  transform: rotateY(90deg);
  transform-origin: 0 50%;
}
.cube:after {
  left: 0;
  bottom: 100%;
  transform: rotateX(90deg);
  transform-origin: 0 100%;
}

@keyframes AnimateContainer {
  100% {
    transform: rotateZ(360deg) rotateX(-30deg) rotateY(-45deg);
  }
}
@keyframes AnimateCube {
  12.5% {
    transform: translate3d(-50%, -50%, 0) scaleY(1);
  }
  25% {
    transform: translate3d(-50%, -50%, 0) scaleZ(2);
  }
  37.5% {
    transform: translate3d(-50%, -50%, 0) scaleZ(1);
  }
  50% {
    transform: translate3d(0, -50%, 0) scaleX(2);
  }
  62.5% {
    transform: translate3d(-50%, -50%, 0) scaleX(1);
  }
  75% {
    transform: translate3d(-50%, 0, 0) scaleY(2);
  }
  87.5% {
    transform: translate3d(-1.5em, -3em, 0) scaleY(0.01);
  }
  100% {
    transform: translate3d(-1.5em, -3em, 0) scaleY(0.01);
  }
}
