/* oswald-regular - latin_cyrillic */
@font-face {
  font-family: "Grotesk";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/akzidenzgroteskpro-regular.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/akzidenzgroteskpro-regular.ttf") format("truetype"); /* Safari, Android, iOS */
}
/* oswald-500 - latin_cyrillic */
@font-face {
  font-family: "Grotesk";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/akzidenzgroteskpro-md.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/akzidenzgroteskpro-md.ttf") format("truetype"); /* Safari, Android, iOS */
}
/* oswald-300 - latin_cyrillic */
@font-face {
  font-family: "Grotesk";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/akzidenzgroteskpro-light.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/akzidenzgroteskpro-light.ttf") format("truetype");
  /* Safari, Android, iOS */
}
/* oswald-600 - latin_cyrillic */
@font-face {
  font-family: "Grotesk Cn";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/akzidenzgroteskpro-cn.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/akzidenzgroteskpro-cn.ttf") format("truetype");
}
/* oswald-700 - latin_cyrillic */
@font-face {
  font-family: "Grotesk";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/akzidenzgroteskpro-bold.ttf"); /* IE9 Compat Modes */
  src: local(""),
    
      url("./assets/fonts/akzidenzgroteskpro-bold.ttf") format("truetype"),
   
}
