.toggleWrapper,
  .toggleWrapperLight {
    width: 34px;
    height: 70px;
    position: fixed;
    background-image: url(../../assets/themeToggle/light.svg);
    background-size: contain;
    top: calc(100% - 80px);
    right: 16px;
    background-repeat: no-repeat;
    z-index: 12;
    cursor: pointer;
  }
  .toggleWrapperLight {
    background-image: url(../../assets/themeToggle/dark.svg);
  }
  .toggle {
    transition: all 0.16s ease-in;
    position: absolute;
  }
  .toggleWrapper .toggle {
    top: 0;
  }
  .toggleWrapperLight .toggle {
    top: 30px;
  }
@media (min-width: 768px) {
  .toggleWrapper,
  .toggleWrapperLight {
    width: 50px;
    height: 90px;
    top: calc(50% + 130px);
    right: 20px;
  }

  .toggleWrapperLight .toggle {
    top: 44px;
  }
}
